@include media-breakpoint-up(md) {
  body {
    background: $grey_background !important;
  }
  body .login_register_container{
    //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, .2);
    .card-body{
      padding: 1.5rem 1.75rem;
    }
  } 
  .RichEditor-root .RichEditor-editor .public-DraftEditor-content {
    min-height: 200px;
  }
  .main .container-fluid{
    padding: 0 30px;
  }
  .team_card .list-group {
    flex-direction: column;
    .list-group-item:first-child{
      border-right: none;
    }
  }
  .sidebar-fixed .sidebar{
    top: 54px;
    z-index: 1021;
  }
}

@include media-breakpoint-up(lg) {
  .RichEditor-root .RichEditor-editor .public-DraftEditor-content {
    min-height: 350px;
  }
}
