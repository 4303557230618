.no_wifi_screen{
  height: 100%;
  
  p,h1{
    text-align: center;
    color: #666;
    max-width: 300px;
    margin: 0 auto;
  }
  h1 {
    font-size: 60px;
  }
  p{
    font-size: 16px;
    margin-bottom: 20px;
  }
}


// Styles
@import './assets/fonts/style.css';
// Import Font Awesome Icons Set
@import '~@fortawesome/fontawesome-free/css/all.css';
// Import Main styles for this application
@import './scss/style.scss';
