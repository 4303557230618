$sidebar_width: 170px;
@include media-breakpoint-up(lg) {
  .sidebar-fixed .sidebar{
    width: $sidebar_width;
  }
  .sidebar .sidebar-nav{
    width: $sidebar_width;
  }
  .sidebar .nav{
    width: $sidebar_width;
  }

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: $sidebar_width;
  }
  .app-header .navbar-brand{
    width: $sidebar_width;
  }
  .breadcrumb{
    left: $sidebar_width;
  }
}
.sidebar .getting_started, .sidebar .go_premium {
  display: none;
}

.sidebar .show_getting_started .getting_started, .sidebar .show_go_premium .go_premium{
  display: block;
  .nav-link{
    background: #20a8d8;
    i.nav-icon{
      color: #FFF;
    }
  }
}

.sidebar .show_getting_started.show_go_premium .go_premium{
  display: none;
}
