.rangeslider{
  margin: 9px 0;
}
.rangeslider-horizontal .rangeslider__handle:after{
  display: none;
}
.rangeslider, .rangeslider .rangeslider__fill{
  box-shadow: none;
}
.rangeslider-horizontal .rangeslider__handle{
  height: 18px;
  width: 18px;
}

.rangeslider-horizontal .rangeslider__fill{
  background-color: #0987b3;
}
