@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ubbj43');
  src:  url('fonts/icomoon.eot?ubbj43#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ubbj43') format('truetype'),
    url('fonts/icomoon.woff?ubbj43') format('woff'),
    url('fonts/icomoon.svg?ubbj43#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="brew-icon-"], [class*=" brew-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brew-icon-co2:before {
  content: "\e900";
}
.brew-icon-grain:before {
  content: "\e901";
}
.brew-icon-hop:before {
  content: "\e902";
}
.brew-icon-mash_fork:before {
  content: "\e903";
}
.brew-icon-yeast:before {
  content: "\e904";
}
.brew-icon-carboy:before {
  content: "\e905";
}
.brew-icon-brew_pot:before {
  content: "\e906";
}
.brew-icon-airlock:before {
  content: "\e907";
}
