// If you want to override variables do it here
@import "./variables";

// coreui override Boostrap variables
// removed from coreui-standalone

@import "./coreui/variables";

// import bootstrap
// importing ^4.6.0 because our and 
// coreui styles depend on it
@import "./bootstrap";

// Import styles
@import "./coreui/coreui-standalone.scss";

// Temp fix for reactstrap
@import './coreui/_dropdown-menu-right.scss';

//react-rangeslider
@import '~react-rangeslider/lib/index.css';

// If you want to add something do it here
@import "./custom";

//global components
@import "./components/sidebar";
@import "./components/dropzone";
@import "./components/slider";
@import "./components/modals";

//sizes
@import "./large-devices";

//layouts
//@import "./narrow_column";
@import "./native_apps";

