@include media-breakpoint-down(xs) {
  .modal-dialog{
    margin: 0;
    height: 100%;
  }
  .modal-header{
    position: fixed;
    z-index: 1000;
    width: 100%;
    background: #FFF;
  }
  .modal-body{
    margin-top: 60px;
  }
  .modal-content{
      border: none;
      border-radius: 0;
      //height: 100%;
  }
  .blue_block_sm{
    border-radius: 0;
  }
}

h5.modal-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-footer{
  justify-content: space-between;
}

.modal-footer .modal_button_split_right{
  margin-left: auto;
}

.modal-body {
  .nav-tabs{
    margin: -1rem -1rem 0 -1rem;
    padding: .5rem .5rem 0 .5rem;
    background: $hover_background_color;
    .nav-link {
      padding: 0.5rem .75rem;
    }
  }
  .tab-content{
    border: none;
    border-top: 1px solid #c8ced3;
    .tab-pane{
      padding: 1rem 0 0;
    }
  }
}

.blue_block{
  height: 150px;
  width: 100%;
  border-radius: 3px 3px 0 0;
  background: $bright_blue url(../../assets/img/getting_started_graphic.png) no-repeat bottom;
  background-size: contain;
}
.blue_block_sm{
  height: 10px;
  width: 100%;
  border-radius: 3px 3px 0 0;
  background: $bright_blue;
  display: flex;
}