.sidebar-fixed .sidebar{
  height: calc(100vh - 55px + env(safe-area-inset-top)) !important;
}

.app-body{
  margin-top: calc(55px + env(safe-area-inset-top)) !important;
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
}
.app-body .main{
  padding-bottom: calc(20px + env(safe-area-inset-top));
}

.redux-toastr .toastr {
  margin-top: calc(10px + env(safe-area-inset-top));
}

.app-header {
  padding-top: env(safe-area-inset-top);
  height: calc(55px + env(safe-area-inset-top));
}

.feedback_component, .update_app_button.btn {
  bottom: calc(10px + env(safe-area-inset-bottom));
}

.modal-footer{
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}

@include media-breakpoint-down(md) {
  .app-header .navbar-brand{
    top: env(safe-area-inset-top);
  }
}
@include media-breakpoint-down(xs) {
  .modal-header{
    padding-top: calc(1rem + env(safe-area-inset-top));
  }
  .modal-body{
    margin-top: calc(60px + env(safe-area-inset-top));
  }
  .modal {
    .getting_started_modal, .iap.subscribe_modal {
      .close{
        padding-top: env(safe-area-inset-top);
      }
      .blue_block_sm{
        height: calc(10px + env(safe-area-inset-top));
      }
      .modal-body {
        padding-bottom: env(safe-area-inset-top);
      }
    }
  }

}
