// Here you can add other styles
html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  background: #fff;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

button:focus {
  box-shadow: none;
  outline: none;
}
.app-body,
.main,
.app-body .main {
  background: #f6f7fb;
  padding-bottom: 20px;
}
.main .container-fluid {
  padding: 0 15px;
}
.helper_popover {
  color: #999;
  font-size: 12px;
}

.opacity-0{
  opacity: 0;;
}

html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
  margin-left: 0;
}

.list_header div div.sort:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.list_header div div.sort_desc:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.ellipsis {
  white-space: nowrap;
  width: 100%; /* IE6 needs any width */
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis;
  display: block;
  float: left;
  width: 130px;
}
.filtered_list {
  .badge {
    text-transform: capitalize;
    background: #dad9d9;
    color: #666;
    i {
      cursor: pointer;
    }
  }
  .dropdown-item i {
    margin-right: 0px;
  }
}

ul.complex_list {
  li {
    position: relative;
    border-bottom: 1px solid #e1e4e8;
    &.header {
      background: $hover_background_color;
      padding: 5px 10px;
      font-weight: bold;
    }
    &.no_highlight:hover {
      background: #fff;
      cursor: auto;
    }
    &:hover {
      background: $hover_background_color;
      cursor: pointer;
    }
    &:last-child {
      border-bottom: none;
    }
    & > i,
    & > input {
      position: absolute;
      top: 10px;
    }
    h6 {
      color: $link_color;
    }
    .list_content {
      padding-left: 23px;
      &.no_icon {
        padding-left: 0;
      }
      p {
        font-size: 12px;
        color: #666;
        span {
          margin-right: 4px;
        }
      }
    }
    .complex_list_row_controls {
      position: absolute;
      right: 13px;
    }
  }
}

/*
.list-group-item{
  border-color: rgba(0, 0, 0, 0.125);
  color: #5c6873;
  margin-bottom: 0;
  border-top: 0;
}

.list-group-item.active{
  background: #f0f3f5;
  border-color: rgba(0, 0, 0, 0.125);
  color: #5c6873;
  margin-bottom: 0;
  border-top: 0;
}
*/

.card {
  //border: 1px solid #e1e4e8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border: none;
}

.card-header {
  background: #fff;
  border-bottom: 1px solid #e1e4e8;
  font-weight: bold;
  strong {
    font-weight: bold;
  }
  .nav {
    .nav-item:last-child .nav-link {
      padding-right: 0;
    }
    .nav-item:first-child .nav-link {
      padding-left: 0;
    }
    .nav-link {
      color: #73818f;
      padding: 5px 10px;
    }
  }
  .card-header-action {
    color: $link_color;
    white-space: nowrap;
  }
}

.card-footer {
  background: #fff;
  border-top: 1px solid #e1e4e8;
}

.app-footer {
  background: #fff;
}

.stripe_form {
  * {
    box-sizing: border-box;
  }
  body,
  html {
    background-color: #f6f9fc;
    font-size: 18px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  h1 {
    color: #32325d;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
    margin: 20px 0;
    padding: 0;
  }
  .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    //padding: 0 5px 20px;
  }
  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    width: 100%;
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 0 0;
    padding: 10px 14px;
    font-size: 1em;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  input::placeholder {
    color: #aab7c4;
  }
  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
}

.dropdown_footer {
  background: #efefef;
  color: #333;
  padding: 7px 20px;
  &:hover {
    background: #ccc;
  }
  i {
    color: #333;
  }
}

.page_header_dropdown {
  & > a {
    font-size: 18px;
  }
  i {
    margin-right: 0;
    margin-left: 0;
    width: auto;
    &.fa-pencil-alt {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

.dropdown-menu.large_list {
  max-height: 300px;
  overflow-y: auto;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #e4e7ea;
  font-size: 14px;
  padding: 15px;
  border-radius: 0.25em;
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }

  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }

  .RichEditor-editor .public-DraftEditor-content {
    height: 200px;
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }

  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: "Hoefler Text", "Georgia", serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 16px;
    padding: 20px;
  }

  .RichEditor-controls {
    font-family: "Helvetica", sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
}

.pagination {
  display: block;
  margin: 0 auto;
  width: 200px;
  button.btn {
    width: 100px;
    &:last-child {
      border-left: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

.color_swatch_sm {
  display: inline-block;
  height: 12px;
  width: 20px;
  vertical-align: middle;
}

.search_input {
  width: 100%;
  position: relative;
  i.fa-search {
    position: absolute;
    top: 10px;
    left: 13px;
    color: #999;
  }
  i.fa-spinner {
    position: absolute;
    top: 11px;
    right: 13px;
    color: #999;
    left: auto;
  }
  input {
    padding: 0.375rem 0.75rem 0.375rem 2rem;
    background: #fff;
  }
}

.picker_button {
  background: #f0f3f5;
  &.invalid {
    border: 1px solid red;
  }
}

.picker_container {
  position: relative;
  width: 100%;
  .picker_list {
    border: 1px solid #eee;
    border-radius: 3px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 0px 4px 9px #ccc;
    width: 100%;
    background: #fff;
    z-index: 1;
    li.selected {
      background: #e1e6e8;
      button {
        color: #000;
      }
    }
  }
}

.card-body ul.primary_list li {
  padding: 7px 10px;
}
ul.primary_list {
  margin-bottom: 0;
  & > li {
    padding: 7px 0;
    border-bottom: 1px solid #e1e4e8;
    &:last-child {
      border-bottom: none;
    }
    .stacked_stats {
      p {
        margin: 0;
        line-height: 14px;
      }
      .stacked_stats_delete_button {
        position: absolute;
        right: 7px;
        top: 0;
      }
    }
    h5 {
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 8px 15px;
      background: $hover_background_color;
      border-top: 1px solid #e1e4e8;
      border-bottom: 1px solid #e1e4e8;
      /* border-radius: 3px; */
      color: #666;
      margin-left: -15px;
      margin-right: -15px;
      font-weight: normal;
    }
  }
}
ul.primary_list,
.modal-body form {
  h5 {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 8px 15px;
    background: $hover_background_color;
    border-top: 1px solid #e1e4e8;
    border-bottom: 1px solid #e1e4e8;
    /* border-radius: 3px; */
    color: #666;
    margin-left: -15px;
    margin-right: -15px;
    font-weight: normal;
  }
}

.sidebar-fixed .sidebar {
  top: auto !important;
}

.top_banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1020;
  height: 47px;
}
.app.with_banner {
  margin-top: 47px;
}

.introduction_helper {
  transition: none !important;
  -webkit-transition: none !important;
  width: 350px;
  margin: 5px;
  text-align: center;
  img {
    width: 100px;
  }
  & > div {
    width: 194px;
    margin: 20px auto 0;
  }
}

//fixes for mobile introduction helper
@include media-breakpoint-down(sm) {
  body.force_sidebar-show .sidebar {
    margin-left: 0 !important;
    transition: none !important;
  }
  body.force_sidebar-show .main::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1018;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    -webkit-animation: none;
    animation: none;
  }
}

span.color_badge {
  background: brown;
  padding: 2px 7px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
}

.stripe_form{
  form label{
    margin-bottom: 0;
    margin-top: 10px;
    color: #333;
  }
  form .StripeElement, form input {
    margin: 0;
    border: 1px solid #CCC;
    box-shadow: none;
  }
  form input#zip {
    width: 100%;
    font-size: 1em;
    ::placeholder {
      
    }
  }
}

.subscribe_modal .modal-body {
  background: #f6f7fb;
  border-radius: 0 0 0.3rem 0.3rem;
}

.global_subscribe_container{
  /* slider */
  /* The switch - the box around the slider */
  .cc_form_wrapper{
    background: #FFF;
    padding: 30px 30px;
    border-radius: 5px;
    //width: 300px;
    margin: 0 auto;
  }
  h1{
    font-size: 50px;
    .price_subtext{
      font-size: 14px;
      color: #999
    }
    sup{
      font-size: 28px;
    }
  } 
  
  .switch {
    margin: 0 auto 20px;
    position: relative;
    display: inline-block;
    width: 225px;
    height: 43px;
    background: rgba(26, 26, 26, 0.05);
    border: 1px solid rgba(26, 26, 26, 0.1);
    border-radius: 22px;
    display: block;

    span.label {
      color: #FFF;
      position: absolute;
      left: 36px;
      top: 10px;
      cursor: pointer;
      -webkit-transition: .4s;
      transition: .4s;
    }

    span.label:last-child {
      left: 143px;
      color: #666;

    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 3px;
      left: 3px;
      width: 50%;
      bottom: 3px;
      border: 1px solid rgba(26, 26, 26, .1);
      -webkit-transition: .4s;
      transition: .4s;
      background-color: $bright_blue;
      border-radius: 22px;

    }
  }

  .price_container.month {
    display: none;
  }

  .monthly {
    .switch {
      .slider {
        -webkit-transform: translateX(106px);
        -ms-transform: translateX(106px);
        transform: translateX(106px);
      }

      span.label {
        color: #666;
      }
      span.label:last-child {
        color: #FFF;

      }

    }

    .price_container.month {
      display: block;
    }

    .price_container.year {
      display: none;
    }
  }

  button.get_started {
    display: block;
    width: 225px;
    height: 43px;
    background: $bright_blue;
    border: 1px solid rgba(26, 26, 26, .1);
    border-radius: 22px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
  }
}

.subscribe_modal.iap .modal-body {
  background: #FFF;
  margin-top: 0;
  padding: 0;
  .close {
    margin: .5rem 1rem;
    color: #FFF;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: 0;
  }
}

.subscribe_modal.iap .modal-header {
  border: none;
}

.update_app_button.btn {
  position: fixed;
  z-index: 1;
  right: 10px;
  bottom: 10px;
  padding: 4px 6px;
}

@include media-breakpoint-down(xs) {
  .modal-backdrop.show {
    background: #fff;
    opacity: 1;
  }
  .modal-footer {
    border-top: none;
    padding-top: 0;
  }
}
