.dropzone{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #666;
  p{
    margin: 100px 20px;
    text-align: center;
  }
}
